
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/http'

import { ElNotification } from 'element-plus'

import Table from '@/components/GridSizeTable/Table.vue'

interface IGrid {
	id: number
	brandId: number
	categoryId: number
	gender: number
	grid: Array<string>
	created: string
	modified: string
}

export default defineComponent({
	name: 'GridSizeViewPage',

	components: { Table },

	setup() {
		const availableGrid = ref([
			{
				key: 'size',
				name: 'Размер',
				instock: false
			},
			{
				key: 'footSize',
				name: 'Размер стопы',
				instock: false
			},
			{
				key: 'intlSize',
				name: 'Международный размер (INT)',
				instock: false
			},
			{
				key: 'brandSize',
				name: 'Размер бренда',
				instock: false
			},
			{
				key: 'breast',
				name: 'Объем груди (см)',
				instock: false
			},
			{
				key: 'waist',
				name: 'Объем талии (см)',
				instock: false
			},
			{
				key: 'hip',
				name: 'Объем бедер (см)',
				instock: false
			},
			{
				key: 'russianSize',
				name: 'Российский размер',
				instock: false
			}
		])

		const route = useRoute()
		const router = useRouter()
		const grid = ref<IGrid>()

		const showTable = ref<boolean>(false)

		const filteredByKey = (key: string) => {
			let resultName = ''

			availableGrid.value.forEach(i => {
				if (i.key === key) resultName = i.name
			})

			return resultName
		}

		const handleDeleteGrid = async () => {
			try {
				const response = await axios.delete(`/grid-size/${route.params.id}`)

				if (response.status === 200) {
					ElNotification({
						title: 'Размерная сетка удалена!',
						type: 'error'
					})
					setTimeout(() => {
						router.push('/grid-size')
					}, 500)
				}
			} catch (e) {
				console.log(e)
			}
		}

		onMounted(async () => {
			try {
				const { data } = await axios.get(`/grid-size/${route.params.id}`)
				grid.value = data
				showTable.value = true
			} catch (e) {
				console.log(e)
			}
		})
		return { showTable, grid, filteredByKey, handleDeleteGrid }
	}
})
